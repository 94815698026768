import "jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive.js"
import "jquery-validation/dist/jquery.validate.js"
import "jquery/dist/jquery.js"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "slick-carousel/slick/slick.js"

import $ from "jquery/dist/jquery.js"

/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
$(function () {

    var unsupportedBrowser = $('.unsupported-browser');
    var unsupportedClose = $('.unsupported-close')
    if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // yay, I am IE11
        unsupportedBrowser.addClass('is-old');

        unsupportedClose.on('click', function () {
            unsupportedBrowser.removeClass('is-old');
        });
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('.sportunionSlider').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        fadeSpeed: 1000
    });

    var $body = $('body');
    var $menuToggle = $('.menu-toggler');
    var $mobileMainNav = $('.mobile-main-navigation');
    var $menuClose = $('.menu-close');
    var $mobileNavItemsWrapper = $('.mobile-navigation-items-wrapper');

    $menuToggle.on('click', function () {
        $mobileMainNav.addClass('open');
        $mobileNavItemsWrapper.removeClass('d-none');
        $body.css('overflow', 'hidden');
        $menuToggle.addClass('d-none');
        $menuClose.removeClass('d-none');
    });

    $menuClose.on('click', function () {
        $mobileMainNav.removeClass('open');
        $mobileNavItemsWrapper.addClass('d-none');
        $('body').css('overflow', 'unset');
        $menuClose.addClass('d-none');
        $menuToggle.removeClass('d-none');
    });

    $('.my-clubbase a').on('click', function (e) {
        e.preventDefault;
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });

    $('.accordion-header').on('click', function (e) {
        e.preventDefault;
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });

    $('[data-fancy-dropdown]').on('click', function (e) {
        var container = $('.fancy-header-region-container');
        if (container.hasClass('show')) {
            //   $('body').css('overflow', 'unset');
            container.removeClass('show');
        } else {
            container.addClass('show');
            //  $('body').css('overflow', 'hidden');
        }
    });


    $('[data-fancy-close]').on('click', function () {
        var container = $('.fancy-header-region-container');
        container.removeClass('show');
        ///  $('body').css('overflow', 'unset');
    });

    $('.clubbase-promo, .sportbase-promo').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        prevArrow: "<div class='slider-button slick-prev'></div>",
        nextArrow: "<div class='slider-button slick-next'></div>"
    });

    $('.sport-association').slick({
        lazyLoad: 'ondemand',
        autoplay: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    });

    const accordion = document.getElementsByClassName("js-accordion-heading");
    var index;

    for (index = 0; index < accordion.length; index++) {

        accordion[index].addEventListener("click", function () {

            this.classList.toggle("active");
            var panel = this.nextElementSibling;

            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }

    // Rework later to scroll to desired id
    $(".js-smooth-scroll").click(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#aanmelden").offset().top - 80
        }, 400);
    });

    $('.sportunion-slider-container').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        prevArrow: "<div class='slider-button slick-prev'></div>",
        nextArrow: "<div class='slider-button slick-next'></div>",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });


    function setSupportChannelWidth() {
        var supportChannel = $('.support-channel');


        if (supportChannel.length == 1) {
            supportChannel.addClass("flex-100");
        }
        else if (supportChannel.length == 3) {
            supportChannel.addClass("flex-30");
        } else {
            supportChannel.addClass("flex-50");
        }
    }
    setSupportChannelWidth();


    // copy to clibboard
    const clibboardBtn = document.querySelectorAll('[data-js-copy]');
    const copyNotification = document.querySelector('[data-js-notification-copy]');
    const closecopyNotification = document.querySelector('[data-js-close-notification]');

    clibboardBtn.forEach(button => {
        
        button.addEventListener('click', function (e) {
            copyNotification.classList.add('copied');
            setTimeout(function () {
                copyNotification.classList.remove('copied');
            }, 2500);

            const btnValue = button.dataset.url;
            navigator.clipboard.writeText(btnValue);

        });
    });

    if (closecopyNotification) {
        closecopyNotification.addEventListener('click', function () {
            copyNotification.classList.remove('copied');
        });
    }

    // Analytics
    function executeAnalyticsLink(category, action) {
        ga('send', 'event', {
            eventCategory: category,
            eventAction: action,
            eventLabel: window.location.href
        });
    }

    function getAnalyticsLinkElements() {
        const linkElements = document.querySelectorAll('[data-analytics-category="shareButtons"]')
        Array.from(linkElements).forEach((element) => {
            element.addEventListener('click', function (e) {
                e.stopPropagation();
                const dataset = element.dataset;
                executeAnalyticsLink(dataset.analyticsCategory, dataset.analyticsAction)
            })
        })
    }
    getAnalyticsLinkElements();
});
